<template>
    <div class="page">
        <div class="background-box">
            <h4>尊敬的合作客户：</h4><p> &nbsp;&nbsp;&nbsp;&nbsp;
            感谢您多年来对我司的配合与支持！为了提升交易体验，提供更加优质的服务及业务便利，
            我司已于2020年上线集业务模块为一体的订购平台。随着公司产品线的不断扩大，
            为了保障交易安全、维护销售体系经营秩序、保障商家的利益，我们谨在此向您郑重通告：
        </p><p>一、线上平台（http://www.jinyijia.com/小程序“金平台”）
            平台系统目前可实现客户订购、自动对账、返利发放确认、发票回执确认等功能，贵司可实现自主下单，
            线上订购、确认即产生相应的法律效力。
        </p><p>二、市场秩序管控要求及返利处罚
            1、遵守销售规则严禁跨区域跨省销售；提升终端销售识别能力，防止终端套机。
            <1>一次异常超基线值扣罚异常产品数量*1倍分货，单型号超过3台，停货一周。
            <2>二次异常超基线值扣罚异常产品数量*2倍分货，单型号超过5台，停货一周。
            <3>针对一二次异常大颗粒违规客户，将视情况停货按月度计算停货周期。
            <4>异常颗粒超大并被中国区通报，且造成不良影响，将视情况停止合作。
            2、对于因市场秩序问题导致国包/厂家侧确认实物窜货等非正常销售行为，或前期多下发需扣除的返利，给予下发【负项返利】。贵司可在系统【返利管理-返利列表-待确认】中查看具体明细；【待确认】" +
            返利需按照下发时间依次进行确认，未按时间顺序依次确认的系统不予执行；返利确认后系统自动结转"
            余额，可在【客户余额—收支类型：返利发放】中查看。例：余额=账款余额+返利/价保-负向返利</p>
            <p>三、反腐内控。公司全员要廉洁纪律，严禁收受与行使职权有关系的单位或个人的礼品、礼金、电子红
                包等，不得滥用职权、徇私舞弊、收受贿赂。加强反廉洁监督，对违反廉洁自律规定的行为可进行匿名
                举报，邮箱：151696@163.com。对有令不行，有禁不止的，一经查实，严肃处理，绝不姑息！
            <p>&nbsp;&nbsp;&nbsp;&nbsp;顺祝商祺。</p>
            <h4 style='text-align: right;'>山东通信科技有限公司</h4><h4 style='text-align: right;'>
            签发日期：2021年04月27日</h4>
            <h4 style='border-bottom: 1px solid #A9A9A9;margin-bottom: 50px'></h4>

        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                params: {},

            }
        },
        mounted() {

        },
        methods: {

        },
        computed: {

        }
    }

</script>

<style lang="less" scoped>
    .background-box {
        min-height: calc(100vh - 0px);
        background: #FFFFFF;
        padding: 10px;
    }

</style>
